<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
            <h2> Gallery | Performance  </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Gallery</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
              <div class="page-title-text">
                  <b-row>
                    <b-col sm="12"><h5>Performing Photos</h5></b-col>
                  </b-row>    
                  <b-row><b-col sm="12 mt-2"><p>Share your photos with us, It will be part of this gallery and will reach out to millions of art lovers.</p></b-col>
                  </b-row>
                    <!-- <b-col sm="12">
                      <div class="thira-button">
                        <router-link to="/share/Photos">Share Your Photos</router-link>
                      </div> 
                    </b-col> -->
              </div>              
                <div class="container">
                    <div class="row ">
                    <div class="col-lg-6 col-md-6 profile-card" v-for="Data in RespUserPhotos" v-bind:key="Data.id">
                      <b-img-lazy v-bind="mainProps" :src="Data.post_img" alt="Thiraseela Photos"></b-img-lazy>
                      <p>{{Data.post_desc}}</p>
                    </div>
                    </div>
                </div>   
             </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <SideRecentPerformers/>
                <!-- sidebar Tags-->
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
Vue.use(Vueaxios,axios)

  export default {
  name:"EventPhotos",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Event Photos | Performing Arts | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
    data() {
      return {
        RespUserPhotos: [],   
        mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
        }
      }
    },
  mounted()
    {
      this.ReadAllUserPhotos()
    },    
    methods: {
        ReadAllUserPhotos(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllUserPhotos')
        .then((resp)=>{
        this.RespUserPhotos=resp.data.UserPhotos;
      })
      },      
    }
  }
</script>

<style scoped>
.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
    height: auto;
}
.profile-card img{
    height: 200px;
    /* padding: 10px 20px 20px 10px; */
    border-radius: 10px;
}

.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
.page-title-text p {
    margin-top: -13px;  
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}
</style>